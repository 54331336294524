var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let default_1 = class default_1 extends Vue {
    get fraction() {
        return `${this.uploader.completedUploadsCount} / ${this.uploader.totalUploads}`;
    }
    get jobDescription() {
        var _a;
        return ((_a = this.uploader.currentJob) === null || _a === void 0 ? void 0 : _a.description) || 'Job Description';
    }
    get percent() {
        return Math.round(this.ratio * 100);
    }
    get ratio() {
        return (this.uploader.completedUploadsCount / this.uploader.totalUploads) || 0;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], default_1.prototype, "uploader", void 0);
default_1 = __decorate([
    Component({ name: 'UploadProgress' })
], default_1);
export default default_1;
