










import Vue, { PropType } from 'vue'
import { Auth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

export default Vue.extend({
  name: 'Login',
  props: {
    appName: {
      required: true,
      type: String
    },
    auth: {
      required: true,
      type: Object as PropType<Auth>
    }
  },
  methods: {
    async doLogin () {
      const provider = new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account', hd: 'htempest.co.uk' })
      await signInWithPopup(this.auth, provider)
      await this.$router.push('/')
    }
  }
})
