var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ImageStatus } from '@ht-lib/accounts-models';
import { getPrepUrl } from '@ht-lib/image-util';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.url = '';
    }
    updateUrl() {
        this.url = getPrepUrl(this.image.longRef, this.settings);
    }
    get statusString() {
        if (this.image.status === ImageStatus.NONE || !this.showStatus)
            return '';
        return this.image.status;
    }
    get baseStyle() {
        return `width:${this.settings.x}px; height:${this.settings.y}px`;
    }
    get className() {
        return this.image.isPreferred ? 'preferred-image' : '';
    }
    created() {
        this.updateUrl();
    }
};
__decorate([
    Prop({ type: Object, required: true })
], default_1.prototype, "image", void 0);
__decorate([
    Prop({ type: Object, default: () => ({ x: 400, y: 400 }) })
], default_1.prototype, "settings", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], default_1.prototype, "showRef", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], default_1.prototype, "showStatus", void 0);
__decorate([
    Watch('settings')
], default_1.prototype, "updateUrl", null);
default_1 = __decorate([
    Component({ name: 'ImageComp' })
], default_1);
export default default_1;
