var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { getDefaultPrepSettings } from '@ht-lib/accounts-models';
import { Component, Vue } from 'vue-property-decorator';
import ImagePrepInputs from './ImagePrepInputs.vue';
import { isEqual, cloneDeep } from 'lodash';
import { ImagePrepPreset } from '@ht-web/firebase-adapter';
function defaultPreset() {
    return {
        name: 'My Preset',
        settings: getDefaultPrepSettings()
    };
}
let DownloadRequestModal = class DownloadRequestModal extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
        this.beforeEdit = null;
        this.preset = defaultPreset();
        this.saving = false;
    }
    get isEdit() {
        return this.beforeEdit != null;
    }
    get hasChanged() {
        return !isEqual(this.beforeEdit, this.preset);
    }
    showModal(preset) {
        console.log('Opening Image Prep preset: ', preset);
        this.beforeEdit = null;
        this.preset = defaultPreset();
        if (preset) {
            this.beforeEdit = cloneDeep(preset);
            this.preset = cloneDeep(preset);
        }
        else {
            this.preset = defaultPreset();
        }
        this.show = true;
    }
    async deletePreset() {
        var _a;
        if (((_a = this.beforeEdit) === null || _a === void 0 ? void 0 : _a.id) != null) {
            await ImagePrepPreset.byId(this.beforeEdit.id).delete();
        }
    }
    update(c) {
        this.preset.settings = cloneDeep(c);
    }
    async save() {
        this.saving = true;
        if (this.isEdit && this.preset.id !== undefined && this.preset.id.length > 0) {
            console.log(`Updating preset ${this.preset.id}`);
            await ImagePrepPreset.byId(this.preset.id).update(this.preset);
        }
        else {
            console.log(`Adding preset ${this.preset.id}`);
            await ImagePrepPreset.addPreset(this.preset);
        }
        this.$emit('input', this.preset);
        this.saving = false;
    }
};
DownloadRequestModal = __decorate([
    Component({
        name: 'ImagePrepPresetModal',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        components: { ImagePrepInputs }
    })
], DownloadRequestModal);
export default DownloadRequestModal;
