var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Prop, Component, Watch, Emit } from 'vue-property-decorator';
import { ImageFileType } from '@ht-lib/accounts-models';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.dpi = 0;
        this.fileTypeOptions = Object.values(ImageFileType);
        this.showAdvanced = false;
        // get inchXRatio () {
        //   return this.prep.xinch / (this.prep.yinch + this.prep.xinch)
        // }
        // get inchArea () {
        //   return (this.prep.xinch * this.prep.yinch)
        // }
        // get pixelArea () {
        //   return this.prep.x * this.prep.y
        // }
    }
    checkForNoValues() {
        if (!this.prep.x && !this.prep.y) {
            this.dpi = 0;
        }
    }
    created() {
        this.updateDPI();
    }
    xPixelChange(c) {
        this.prep.x = Math.round(c);
        this.updateDPI();
    }
    yPixelChange(c) {
        this.prep.y = Math.round(c);
        this.updateDPI();
    }
    xInchChange(c) {
        this.prep.xinch = Math.round(c);
        this.updatePixels();
    }
    yInchChange(c) {
        this.prep.yinch = Math.round(c);
        this.updatePixels();
    }
    dpiChange(c) {
        this.dpi = c;
        this.updatePixels();
    }
    updateDPI() {
        if (this.prep.xinch !== undefined &&
            this.prep.yinch !== undefined &&
            this.prep.x !== undefined &&
            this.prep.y !== undefined) {
            const y = this.prep.y / this.prep.yinch;
            const x = this.prep.x / this.prep.xinch;
            this.dpi = Math.round((y + x) / 2);
        }
        return this.prep;
    }
    updatePixels() {
        if (this.prep.xinch !== undefined && this.prep.yinch !== undefined) {
            this.prep.x = this.dpi * this.prep.xinch;
            this.prep.y = this.dpi * this.prep.yinch;
        }
        return this.prep;
    }
};
__decorate([
    Prop({ required: true })
], default_1.prototype, "prep", void 0);
__decorate([
    Watch('prep.x')
], default_1.prototype, "checkForNoValues", null);
__decorate([
    Emit('input')
], default_1.prototype, "updateDPI", null);
__decorate([
    Emit('input')
], default_1.prototype, "updatePixels", null);
default_1 = __decorate([
    Component({
        name: 'ImagePrepInputs'
    })
], default_1);
export default default_1;
