import App from '@/App.vue'
import '@/quasar'
import { setPrepMode } from '@ht-lib/image-util'
import VueGoogleSignIn from '@ht-lib/vue-google-signin'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { auth } from './firebase'
import router from './router'

setPrepMode(process.env.NODE_ENV === 'production')
Vue.use(Vuelidate)
Vue.use(new VueGoogleSignIn(), {
  router,
  appName: 'Image Manager',
  firebaseAuth: auth,
  defaultRouteName: 'default'
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
