var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { filterFiles } from '@ht-lib/image-util';
import { Component, Vue, Ref } from 'vue-property-decorator';
let default_1 = class default_1 extends Vue {
    inputFileList(fileList) {
        const files = filterFiles(Array.from(fileList));
        this.$emit('input', files);
    }
    selectJobButtonClicked() {
        this.selectJobInput.click();
    }
};
__decorate([
    Ref()
], default_1.prototype, "selectJobInput", void 0);
default_1 = __decorate([
    Component({
        name: 'FileSelector'
    })
], default_1);
export default default_1;
