var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { UserProfile, Job } from '@ht-web/firebase-adapter';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.job = null;
        this.photographer = null;
    }
    get photographerName() {
        var _a, _b, _c, _d;
        const name = (_b = (_a = this.photographer) === null || _a === void 0 ? void 0 : _a.displayName) !== null && _b !== void 0 ? _b : '';
        const code = (_d = (_c = this.image) === null || _c === void 0 ? void 0 : _c.photographerCode) !== null && _d !== void 0 ? _d : '';
        return name || code ? `${name} (${code})` : '-';
    }
    get jobNotes() {
        var _a, _b;
        return ((_b = (_a = this.job) === null || _a === void 0 ? void 0 : _a.notes) !== null && _b !== void 0 ? _b : '') || '-';
    }
    get takeDate() {
        var _a;
        if (typeof ((_a = this.image) === null || _a === void 0 ? void 0 : _a.takeDate) !== 'string') {
            return '-';
        }
        return format(new Date(this.image.takeDate), 'HH:mm - dd MMM yyyy');
    }
    async loadPhotographer() {
        var _a;
        this.photographer = null;
        if (typeof ((_a = this.image) === null || _a === void 0 ? void 0 : _a.photographerCode) !== 'string') {
            return;
        }
        this.photographer = await UserProfile.byPhotographerCode(this.image.photographerCode).singleOrNull();
    }
    async loadJob() {
        var _a;
        this.job = null;
        if (typeof ((_a = this.image) === null || _a === void 0 ? void 0 : _a.jobId) !== 'string') {
            return;
        }
        this.job = await Job.byId(this.image.jobId).singleOrNull();
    }
};
__decorate([
    Prop({ required: true })
], default_1.prototype, "image", void 0);
__decorate([
    Watch('image.photographerCode', { immediate: true })
], default_1.prototype, "loadPhotographer", null);
__decorate([
    Watch('image.jobId', { immediate: true })
], default_1.prototype, "loadJob", null);
default_1 = __decorate([
    Component({ name: 'ImageDetails' })
], default_1);
export default default_1;
