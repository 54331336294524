import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, _from, next) => {
  if (to.name === 'login') return next()

  const authed = typeof auth.currentUser === 'object'
  if (!authed) {
    return router.replace({ name: 'login' })
  }

  next()
})
