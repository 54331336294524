import '@quasar/extras/material-icons/material-icons.css';
import Quasar from 'quasar';
import Vue from 'vue';
// Vue.use({ install }, {
//   components: [
//     QBtn,
//     QCard,
//     QCheckbox,
//     QDialog,
//     QExpansionItem,
//     QHeader,
//     QInput,
//     QItem,
//     QItemLabel,
//     QItemSection,
//     QLayout,
//     QPage,
//     QPageContainer,
//     QSelect,
//     QSeparator,
//     QToolbar,
//     QToolbarTitle
//   ],
//   directives: [
//     ClosePopup,
//     Ripple
//   ],
//   plugins: [],
//   config: {}
// })
Vue.use(Quasar, {
    config: {},
    components: { /* not needed if importStrategy is not 'manual' */},
    directives: { /* not needed if importStrategy is not 'manual' */}
    // plugins: { }
});
