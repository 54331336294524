import Default from '@/layouts/Default/Default.vue'
import { RouteConfig } from 'vue-router'

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: Default,
    children: [
      {
        path: '',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/index.vue'),
        name: 'default'
      },
      {
        path: '/editing/view/:jobId',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/EditJobView.vue')
      },
      {
        path: '/editing',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/EditJobs.vue')
      },
      {
        path: '/download',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Download.vue')
      }
    ]
  },
  {
    path: '/401',
    name: '401',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error401.vue')
  },
  { // Always leave this as last one
    path: '*',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error404.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about", webpackPrefetch: true */ '../views/About.vue')
  // }
]

export default routes
