import * as accountsCommon from '@ht-lib/accounts-common'
import * as imageUtil from '@ht-lib/image-util'
import { getDefaultAuth, initializeCompat } from '@ht-web/firebase-adapter'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

export const app = initializeCompat({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
})

accountsCommon.setApp(app)
imageUtil.setApp(app)

export const auth = getDefaultAuth()
